.zhm--empty-list {
    margin-top: -2px;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        text-align: center;
        background: $white;
        border: 1px solid $grey-300;
        border-radius: 4px;

        button {
            margin-top: 1rem;
        }
    }

    p {
        margin-bottom: 0;
        line-height: 1.5;
        font-size: 40px;
        font-weight: 300;
    }

    .action-buttons {
        margin-top: 1rem;

        button {
            margin-top: 0;
        }
    }
}

@include media-breakpoint-down(md) {
    .zhm--empty-list {
        margin: 0.5rem;
    }
}
