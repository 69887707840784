/* CONTENT
    Style for shared ui elements
    1. Fonts, text related
    2. Buttons
    3. Position, layout, display
    4. Statuses
    5. Forms
    6. Errors
    7. Miscellaneous

*/

html {
    font-size: 14.5px;
}

.container-fluid {
    position: relative;
}

/* FONTS, TEXT RELATED */
//The most often used text formats
.font-thin {
    font-weight: 300;
}

.text-lighter {
    opacity: 0.5;
}

.text-bold {
    font-weight: 500;
}

.font-bold {
    font-weight: bold;
}

$fonts: (
    'xxlarge': $font-xxlarge,
    'xlarge': $font-xlarge,
    'large': $font-large,
    'default': $font-default,
    'small': $font-small,
    'xsmall': $font-xsmall,
    'xxsmall': $font-xxsmall,
    'inherit': inherit
);

@each $name, $font in $fonts {
    .text-#{$name}-dark {
        font-size: $font;
    }
}

@each $name, $font in $fonts {
    .text-#{$name}-light {
        font-size: $font;
        color: $grey-base-800;
    }
}

@each $name, $font in $fonts {
    .text-#{$name}-uppercase {
        font-size: $font;
        text-transform: uppercase;
    }
}

.text-danger,
.text-red-base-700 {
    color: $red-base-700 !important;
}

.text-blue-base-700 {
    color: $blue-base-700 !important;
}

.text-grey-700 {
    color: $grey-700 !important;
}

.text-grey-base-800 {
    color: $grey-base-800 !important;
}

.text-blue-800 {
    color: $blue-800 !important;
}

//Headers
.hr-thin {
    border-top: 2px solid $grey-300;
    box-shadow: none;
}

.h-underlined {
    margin: 2rem 0;
    padding-bottom: 1rem;
    font-size: 1.125rem;
    font-weight: 500;
    border-bottom: 1px solid $grey-200;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    &,
    &:hover {
        color: $black;
        text-decoration: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

//Text colors
$colors: (
    'red': $red-base-700,
    'yellow': $yellow-base-700,
    'green': $green-base-700,
    'blue': $blue-base-700,
    'black': $black,
    'orange': $yellow-base-700,
    'gray': $grey-base-800,
    'default': $black-base-900
);

@each $name, $color in $colors {
    .text-#{$name} {
        color: $color;
    }
}

//Highlighter
.highlight {
    color: $grey-600;
    font-weight: $font-weight-bolder;
}

//When text overflows it is adding ellipsis '...'
.overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
}

.nowrap {
    white-space: nowrap;
}

.border-square {
    border-radius: 0.25rem;
}

/* BUTTONS */

//Delete all typical button styles
.btn-clear {
    border: 0;
    background: inherit;
    width: auto;
    color: inherit;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &[disabled] {
        opacity: 0.4;
        cursor: default;
    }
}

//Old button styles, please rather use zhm-button-pill component
.btn {
    cursor: pointer;
    width: auto;
    font-size: 0.875rem;
    font-weight: $font-weight-bolder;
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    &[disabled] {
        cursor: default;
        background-color: $grey-200;
        border-color: $grey-200;
        color: $grey-400;
    }

    &.btn-default {
        border: 1px solid $grey-600;
    }

    &.btn-square {
        border-radius: 0.25rem;
    }

    &.btn-rounded-light {
        border-radius: 0.2rem;
    }

    &.btn-gray {
        color: $black-base-900;
        background-color: $grey-300;

        &:active,
        &:hover {
            background-color: darken($grey-300, 5%);
        }
    }

    &.btn-outline-secondary {
        color: $grey-base-800;
        border-color: $grey-base-800;

        &:hover {
            color: $white;
            background-color: $grey-base-800;
        }
    }

    &.btn-outline-primary.btn-no-hover {
        &:hover,
        &:active {
            color: $blue-base-700;
            border-color: $blue-base-700;
            background-color: $white;
        }
    }

    &.btn-dashed-info {
        border: 1px dashed $grey-400;
        background: inherit;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }

    &.btn-secondary {
        color: $black-base-900;
        border-color: $grey-300;
        background-color: $grey-300;

        &:hover,
        &:focus,
        &:active {
            border-color: $grey-400 !important;
            background-color: $grey-400 !important;
        }
    }

    &.btn-danger {
        color: $white;
        border-color: $red-base-700;
        background-color: $red-base-700;

        &:hover,
        &:focus,
        &:active {
            border-color: $red-800 !important;
            background-color: $red-800 !important;
        }
    }

    &.btn-outline-danger {
        color: $red-base-700;
        background-color: $white;
        border-color: $red-base-700;

        &:hover,
        &:active,
        &:focus {
            color: $white;
            border-color: $red-base-700 !important;
            background-color: $red-base-700 !important;
        }
    }

    &.btn-outline-danger-no-border {
        color: $red-base-700;
        background-color: $white;
        border-color: none !important;

        &:hover,
        &:active,
        &:focus {
            border-color: $red-base-700 !important;
        }
    }
}

.btn-outline-primary {
    border-color: $grey-600;
    color: $grey-600;
    &:hover {
        border-color: $grey-600;
        color: $grey-600;
    }
}

.btn-primary {
    &:hover {
        color: $grey-600;
    }
}

zhm-page-footer,
.modal-footer {
    .btn-save,
    .btn-next {
        background-color: $blue-base-700;
        color: $white;
    }
}

zhm-page-footer {
    .btn {
        min-width: 9.7rem;
    }
}

//Loading button effect
.btn > .spinner {
    display: none;
}

.btn.loading {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 20px;
        background: rgba(100, 100, 100, 0.5);
    }

    > .spinner {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        text-indent: -99999em;
        color: transparent;
        transform: translateZ(0);

        &:after,
        &:before {
            content: '';
            position: absolute;
            top: 0;
            width: 16px;
            height: 32px;
            box-sizing: border-box;
            border-color: $white;
            border-style: solid;
        }

        &:before {
            left: 0;
            border-width: 6px 0 6px 6px;
            border-radius: 16px 0 0 16px;
            background: transparent;
            transform-origin: 16px 16px;
            animation: spinning 2s infinite ease 1.5s;
        }

        &:after {
            left: 16px;
            background: transparent;
            border-width: 6px 6px 6px 0;
            border-radius: 0 16px 16px 0;
            transform-origin: 0 16px;
            animation: spinning 2s infinite ease;
        }
    }
}

//Creates triangle on top center of the element with specified border color (triangle white inside)
$border-colors: (
    'blue': $blue-base-700,
    'alto': $grey-400
);

@each $name, $border-color in $border-colors {
    .triangle-#{$name}-border {
        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 50%;
            margin-left: -7px;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
        }

        &:before {
            top: -9px;
            border-bottom: 8px $border-color solid;
        }

        &:after {
            top: -8px;
            border-bottom: 8px white solid;
        }
    }
}

/* POSITION, LAYOUT, DISPLAY */
//Often used

.block {
    display: block;
}

.flex {
    display: flex;
}

.flex-1 {
    flex: 1;
}

.relative {
    position: relative;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.clearfix:after {
    content: ' ';
    display: table;
    clear: both;
}

.pointer {
    cursor: pointer;
}

.invisible {
    visibility: hidden;
}

.scrollable {
    overflow-y: scroll;
}

.auto-scroll {
    overflow: auto;
}

.position-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-basis-0 {
    flex-basis: 0;
}

.flex-basis-100 {
    flex-basis: 100%;
}

//Extension to bootsrap to allow specifing different column system for mobiles below 320px. Should be used before col
@media (max-width: 320px) {
    @for $i from 1 through 12 {
        .col-xs-#{$i} {
            flex: 0 0 percentage(($i / 12));
            max-width: percentage(($i / 12));
        }
    }
}

/* STATUSES */
.background {
    &-danger {
        background-color: $red-200;
        color: $red-base-700;
    }

    &-success {
        background-color: $green-200;
        color: $green-base-700;
    }

    &-warning {
        background-color: $yellow-200;
        color: $yellow-base-700;
    }

    &-info {
        background-color: $grey-300;
        color: $grey-base-800;
    }
}

.label {
    &-danger {
        color: $red-base-700;
    }

    &-success {
        color: $green-base-700;
    }

    &-warning {
        color: #e67e23;
    }

    &-info {
        color: $grey-base-800;
    }

    &-default {
        color: $black-base-900;
    }
}

/* FORMS */
.form-group {
    margin-bottom: 0;

    > .label {
        display: block;
        padding-bottom: 0.5em;
        font-size: 0.9rem;
        font-weight: bold;
    }

    > .value {
        display: block;
        padding: 0.8em 1em;
        border-bottom: 1px solid $grey-400;
        font-size: 85%;
    }

    > .select {
        position: relative;

        &:after {
            content: '\e42c';
            position: absolute;
            right: 0;
            top: 0;
            padding: 0.5em 1em;
            font-family: legacy-icons;
            font-size: 18px;
            pointer-events: none;
        }

        > select {
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            appearance: none;
            padding-right: 3em;
        }
    }
}

form input,
form select {
    display: block;
    width: 100%;
    height: 40px;
}

.form-control {
    border-radius: 0.2rem;
    background-color: $white;
    border-color: $grey-400;
    transition: $transition;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    color: $black-base-900;

    &:focus,
    &:disabled {
        background: $white;
    }

    &:focus {
        border: 1px solid $blue-base-700;
        box-shadow: none;
        color: inherit;
    }

    &::placeholder {
        color: $grey-300;
    }

    &[readonly] {
        cursor: not-allowed;
        background: $grey-200;

        &:focus {
            background: $grey-200;
            border-color: $blue-200;
            color: $black-base-900;
        }
    }
}

input[type='checkbox'].form-control {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    float: left;
    background-color: $grey-300;
    border: solid 2px darken($grey-300, 5%);
}

.form-group.required label:after,
label.required:after {
    content: '*';
    padding: 0 0.25em;
    color: red;
}

.form-group.optional label:after,
label.optional:after {
    content: '(optional)';
    position: relative;
    top: -0.1em;
    padding: 0 0.8em;
    font-size: 80%;
}

select.form-control:not([size]):not([multiple]) {
    margin: 0;
    height: 40px;
}

zhm-wid-numeric-spinner {
    padding: 0 !important;
    border: none !important;
}

/* ERRORS */
.errors {
    color: $red-base-700;
    min-height: 32px;
    font-size: 0.9em;
}

.error input,
.error select,
input.error,
select.error {
    background: $red-200;

    &:focus {
        background: $red-200;
    }
}

// bootstrap override
.background-danger {
    color: $white;
}

/* MISCELLANEOUS */
// TO DO- review after accounting is introduced if still needed

.with-euro-sign {
    div.euro-sign-container {
        position: relative;

        input {
            padding-right: 32px;
        }

        &:after {
            content: '€';
            position: absolute;
            right: 12px;
            top: 8px;
            font-size: 0.9rem;
            line-height: 24px;
        }
    }
}

input.search {
    margin-right: 32px;
    min-width: 350px;
}

.zhm--label {
    height: 24px;
    border-radius: 3px;
    border: solid 1px $grey-base-800;
    display: inline-block;
    text-align: center;
    padding: 0 16px;
    line-height: 24px;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}

/* Restyle Google Maps Place dropdown widget */
.pac-container {
    z-index: 9999999 !important;
}

[data-zhmInfiniteListEnd] {
    height: 1px;
    width: 1px;
}

.mandatory::after {
    content: ' *';
}

/* Reusable Grid/Width Classes  */

.max-w-1125 {
    max-width: 11.25rem;
}

.max-w-8 {
    max-width: 8rem;
}

.w-1125 {
    width: 11.25rem;
}

.w-1275 {
    width: 12.75rem;
}

.max-w-18 {
    max-width: 18rem;
}

.max-w-5-5 {
    max-width: 5.5rem;
}

.w-30 {
    width: 30%;
}

.w-40 {
    width: 40%;
}

.h-100vh {
    height: 100vh;
}

.line-height-0875 {
    line-height: 0.875rem;
}

.line-height-1 {
    line-height: 1rem;
}

/* Reusable utils */

.border-none {
    border: none !important;
}

.opacity-1 {
    opacity: 1;
}

.icon-3x {
    font-size: 1rem;
}

.icon-4x {
    font-size: 1.25rem;
}

.icon-5x {
    font-size: 1.5rem;
}

.icon-6x {
    font-size: 1.75rem;
}

.bg-white {
    background-color: $white;
}

.bg-gray {
    background-color: $grey-100;
}

.bg-blue-light {
    background-color: $blue-300;
}

/*
Global style used in all sections
of the rental contract editor
 */
.read-view {
    background-color: $grey-200;

    label {
        color: $grey-base-800;
    }

    .section-heading {
        height: 1.5rem;
    }
}

/*
Global style for blue checkmarks in translation
list items which are rendered as html - this style
is specifically for the Multi-listing product widget
*/
ul.ml-widget {
    li {
        display: block;
        text-indent: -0.6rem;
        margin-bottom: 0.3rem;
        margin-left: 2rem;
        font-weight: 500;
    }

    li:before {
        font-family: legacy-icons !important;
        content: '\e2ec';
        color: $blue-base-700;
        display: inline-block;
        width: 0.5rem;
        font-size: 0.625rem;
        line-height: 1rem;
        vertical-align: middle;
        margin-right: 0.1rem;
    }
}

/*
Global style for green checkmarks in translation
list items which are rendered as html - these styles
are specifically for product widgets in the rental
journey pages
*/
ul.prod-widget-description {
    li {
        display: block;
        text-indent: -0.6rem;
        margin-bottom: 0.3rem;
        margin-left: 2rem;
        font-weight: 500;
    }

    li:before {
        font-family: legacy-icons !important;
        content: '\e2ec';
        color: $green-base-700;
        display: inline-block;
        width: 0.5rem;
        font-size: 0.625rem;
        line-height: 1rem;
        vertical-align: middle;
        margin-right: 0.1rem;
    }
}

// Global style override for mobile modal - for rental contract product widget
@include media-breakpoint-down(md) {
    .rc-product-widget {
        .modal-body {
            padding-bottom: 0rem !important;
        }
    }
}
