@-webkit-keyframes spinning {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinning {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes flowUp {
    0% {
        transform: translateY(0);
    }
    80% {
        opacity: 1;
    }
    100% {
        transform: translateY(-15px);
        opacity: 0;
    }
}

@keyframes flowUp1 {
    0% {
        transform: translateY(0);
    }
    80% {
        opacity: 1;
    }
    100% {
        transform: translateY(-30px);
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes flash {
    0%,
    32%,
    64%,
    100% {
        background: none;
    }

    16%,
    48%,
    80% {
        background: $grey-200;
    }
}

@keyframes flash-once {
    0%,
    100% {
        background: none;
    }

    50% {
        background: $grey-200;
    }
}
