$font-details: 0.75rem;

.modal-backdrop {
    background-color: #000;
    transition: opacity none;
    &.fade,
    &.show {
        opacity: 0.7;
    }
}

.dialog--wizard .outer-flex-container {
    background-color: rgba(0, 0, 0, 0.7) !important;
    overflow-y: hidden !important;
}

.dialog--wizard {
    .zhm--dialog-body-wrapper {
        padding: 1.5rem !important;
    }
}

@include media-breakpoint-down(sm) {
    .dialog--wizard div.zhm--dialog[dlg='Confirmation'] {
        max-width: 100vw !important;
    }
}

.modal-dialog {
    margin-top: 0px;
    max-width: $modal-width;
    background-color: $grey-100;
    pointer-events: auto;
}

.modal-content {
    background: #f5f5f5;
    height: $modal-height;
    max-width: $modal-width;
    border-radius: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.37);
}

.modal-header {
    border-bottom: 2px solid $grey-400;
    h4 {
        font-size: $font-large;
    }
}

.modal-header-no-padding {
    .modal-header {
        padding: 0 0.5rem;

        .text-large-dark {
            color: $grey-600;
        }
    }
}

.modal-title {
    flex: 1 1 auto;
    text-align: center;
    font-size: 1.25rem;
}

.modal-body {
    width: 60%;
    margin: 0 auto;
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: 5rem;
}

.modal-footer {
    display: flex;
    justify-content: center;
    .btn {
        width: 50%;
        text-transform: uppercase;
        &:focus {
            box-shadow: none;
        }
    }
    .btn-back {
        background-color: white;
        i {
            font-size: $font-details;
        }
    }
}

.modal-content-height {
    display: flex;
    flex-direction: column;
    height: $modal-height;
}

.modal-upload-logo .modal-content {
    height: 390px;
}

//STYLES FOR ANIMATED DIALOGS
//adjustments for default ng bootstrap modal animation
.modal.fade {
    .modal-dialog.sliding-from-right {
        transform: translate(50%, 0);
        transition: all 450ms ease-out;
        overflow-x: hidden;
    }
    .modal-dialog.sliding-from-top {
        transform: translate(0, -60%);
        transition: all 400ms ease-out;
        overflow-y: hidden;
    }
    .modal-dialog.sliding-from-bottom {
        transform: translate(0, 100%);
        transition: all 400ms ease-out;
        overflow-y: hidden;
    }
    &.show {
        opacity: 1;
        .modal-dialog {
            transform: translate(0, 0);
            opacity: 1;
        }
        .modal-dialog.sliding-from-right,
        .modal-dialog.sliding-from-top,
        .modal-dialog.sliding-from-bottom {
            transition: all 350ms ease-out;
        }
    }
}
//animation for dialogs without using ng bootstrap animations by adding class to modals
.modal.show {
    .modal-dialog {
        transition: transform 300ms ease-in;
    }
}

.modal-dialog.overflow-hidden {
    overflow: hidden;
}

.modal-dialog.modal-fit-content {
    .modal-content {
        height: fit-content;
    }
}

.modal-dialog.profit-loss-modal,
.modal-dialog.loan-contracts-editor {
    .modal-content {
        height: auto;
    }
}

@include media-breakpoint-up(lg) {
    .modal-dialog.full-screen-dialog {
        margin: 0;
        max-width: 100vw;
        width: 100vw;
        height: 100vh;
        .modal-content {
            height: 100vh;
            max-width: 100vw;
            width: 100vw;
        }
        .modal-title {
            font-weight: 500;
        }

        .modal-content-height {
            height: 100vh;
        }
    }

    .modal-dialog.zhm-new-lease-popup {
        .modal-body {
            padding-bottom: 4rem;
        }
    }

    .modal-dialog.dialog-custom-size {
        margin-top: 15vh;
        max-width: $modal-width-bg;
        .modal-content {
            height: auto;
            max-height: 75vh;
            max-width: $modal-width-bg;
        }
    }

    .modal-dialog.property-edit-popup-size {
        margin-top: 10vh;
        max-width: 500px;
        border-radius: 0.25rem;
        .modal-content {
            height: auto;
            max-height: 75vh;
            max-width: 500px;
            border-radius: 0.25rem;
            box-shadow: none;
        }
    }
}

@include media-breakpoint-down(md) {
    .modal-backdrop {
        background: $white;
    }

    //blocker for body scrolling when modals are open
    .modal-open {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        -webkit-overflow-scrolling: touch; //needed for iOS - adding momentum to scrolling
    }

    modal-container {
        background: transparent;
    }

    modal-container,
    .modal-dialog,
    .modal-dialog.dialog-custom-size {
        margin: 0;
        min-height: 100%;
        width: 100%;
    }

    modal-container,
    .modal-dialog,
    .modal-dialog.property-edit-popup-size {
        margin: 0;
        min-height: 100%;
        width: 100%;
    }

    .modal-dialog {
        max-width: 100%;
    }

    .modal-content {
        box-shadow: none;
        max-width: 100%;
        width: 100%;
        min-height: inherit;
        border: 0;
        height: calc(100vh - 4rem); //needed to make footer always visible on the mobile
    }

    .modal-dialog-no-footer {
        .modal-content {
            height: 100vh !important;
        }
    }

    .modal-content-height {
        height: calc(100vh - 3.5rem);
    }

    .modal-header {
        .close {
            order: -1;
            opacity: 1;
            text-shadow: none;
            color: inherit;
            padding: 0;
            margin: 0;
            i {
                font-size: 1.2rem;
            }
        }
    }

    .modal-body {
        width: 100%;
        padding: 0.5rem;
        padding-bottom: 6.5rem;
    }
    .modal-footer {
        padding: 0;
        height: $topbar-tablet-height;
        border-top: none;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: $dialog-footer-index;
        .btn {
            width: 100%;
            height: 100%;
            border-radius: 0;
            margin: 0;
            &.disabled {
                opacity: 0.3;
            }
        }
        .btn-back {
            background-color: $grey-200;
        }
    }

    .modal.fade {
        .modal-dialog.tablet-overlay {
            transform: translate(0, 0);
            .modal-content {
                transform: translate(50%, 0);
                transition: all 450ms ease-out;
                overflow-x: hidden;
            }
        }
        &.show {
            .modal-dialog.tablet-overlay {
                .modal-content {
                    transform: translate(0, 0);
                    opacity: 1;
                    transition: all 350ms ease-out;
                }
            }
        }
    }

    .modal-dialog.tablet-overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.45);
        .modal-content {
            position: fixed;
            top: 0;
            right: 0;
            height: 100%;
            width: 50%;
        }
    }
}

@include media-breakpoint-down(sm) {
    .modal-dialog.tablet-overlay {
        .modal-content {
            width: 100%;
        }
    }
}
