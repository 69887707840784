/**
* @todo Import Font from local file or from our CDN
*/
@font-face {
    font-family: 'Make It Sans IS24 Web';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://www.static-immobilienscout24.de/fro/core/4.4.2/font/vendor/make-it-sans/MakeItSansIS24WEB-Regular.woff2)
            format('woff2'),
        url(https://www.static-immobilienscout24.de/fro/core/4.4.2/font/vendor/make-it-sans/MakeItSansIS24WEB-Regular.woff)
            format('woff');
}

@font-face {
    font-family: 'Make It Sans IS24 Web';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://www.static-immobilienscout24.de/fro/core/4.4.2/font/vendor/make-it-sans/MakeItSansIS24WEB-Bold.woff2)
            format('woff2'),
        url(https://www.static-immobilienscout24.de/fro/core/4.4.2/font/vendor/make-it-sans/MakeItSansIS24WEB-Bold.woff)
            format('woff');
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Make It Sans IS24 Web', Roboto, sans-serif;
}

html {
    -moz-osx-font-smoothing: grayscale;
    & {
        -webkit-font-smoothing: antialiased;
    }
}

body {
    background: #f5f5f5;
    color: $black-base-900;
    min-height: 100vh;
    @media (max-width: 720px) {
        overflow-x: hidden;
    }
}

#app-zenhomes {
    padding-left: 0;
    transition: all 0.5s ease;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;

    > .container-fluid {
        padding: 0;
    }
}

@media (min-width: 768px) {
    #page-content-wrapper {
        position: relative;
    }
}
