$animation-xxfast: 100ms;
$animation-xfast: 200ms;
$animation-fast: 300ms;
$animation-normal: 400ms;
$animation-slow: 600ms;
$animation-xslow: 800ms;
$animation-xxslow: 1000ms;
$animation-xxxslow: 1200ms;

@keyframes zhmFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}
