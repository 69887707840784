.zhm-tooltip {
    min-width: 4.6rem;
    font-size: 0.7rem;
    background-color: $grey-700;
    color: $white;
    border: 1px solid $grey-700;
    text-align: center;
    padding: 3px 5px;
    border-radius: 0.25rem;
    position: absolute;
    z-index: 1000;
    display: block;
    opacity: 0;
    transition: opacity 300ms;
}
.zhm-tooltip-show {
    opacity: 1;
}

.zhm-tooltip-top {
    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -5px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
    }
    &:before {
        top: calc(100% + 1px);
        border-top: 6px $grey-700 solid;
    }
    &:after {
        top: 100%;
        border-top: 6px $grey-700 solid;
    }
}

.zhm-tooltip-bottom {
    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -5px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
    }
    &:before {
        bottom: calc(100% + 1px);
        border-bottom: 6px $grey-700 solid;
    }
    &:after {
        bottom: 100%;
        border-bottom: 6px $grey-700 solid;
    }
}

.zhm-tooltip-left {
    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -5px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
    }
    &:before {
        left: calc(100% + 1px);
        border-left: 6px $grey-700 solid;
    }
    &:after {
        left: 100%;
        border-left: 6px $grey-700 solid;
    }
}

.zhm-tooltip-right {
    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -5px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
    }
    &:before {
        right: calc(100% + 1px);
        border-right: 6px $grey-700 solid;
    }
    &:after {
        right: 100%;
        border-right: 6px $grey-700 solid;
    }
}
