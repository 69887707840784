.btn-spinner {
    position: relative;

    &:disabled {
        opacity: 0.8;

        & > .match-text {
            opacity: 0;
        }
    }

    .spinner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: absolute;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: white;
    }
}
