.icon--spin {
    display: inline-block;
    animation: spin 2s infinite linear;
}

.icon--pulse {
    display: inline-block;
    animation:  spin 1s infinite steps(8);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.icon--fw {
    width: (18em / 14);
    text-align: center;
}

.icon--lg {
    font-size: (4em / 3);
    line-height: (3em / 4);
    vertical-align: -15%;
}

.icon--2x { font-size: 2em; }
.icon--3x { font-size: 3em; }
.icon--4x { font-size: 4em; }
.icon--5x { font-size: 5em; }

// Stacked Icons

.icon--stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;
}

.icon--stack-1x, .icon--stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
}

.icon--stack-1x { line-height: inherit; }
.icon--stack-2x { font-size: 2em; }
