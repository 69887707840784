.dropdown {
    .form-control {
        position: relative;
        padding-right: 1.5rem;
        min-height: 2.18rem;
        cursor: pointer;
    }

    [dropdownToggle] {
        cursor: pointer;
    }

    .label {
        @include text-truncate();
        user-select: none;
    }

    i {
        position: absolute;
        top: 0.6rem;
        right: 0.5rem;
    }

    .dropdown-menu {
        @include text-truncate();
        z-index: 1;
        width: 100%;
        padding: 0;
        border-right: 1px solid $grey-300;
        border-left: 1px solid $grey-300;
        user-select: none;

        .dropdown-item {
            height: 2.18rem;
            padding: 0 1rem;
            border-bottom: 1px solid $grey-300;
            font-size: 0.875rem;
            line-height: 2.18rem;
            color: $grey-base-800;
            cursor: pointer;

            &.active,
            &:hover {
                background: $grey-200;
                border-bottom: 1px solid $grey-300;
            }
        }
    }
}
