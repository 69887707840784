.zhm--page {
    width: 100%;
    margin: 0;
    padding-top: 0.5rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    padding-bottom: 0rem;
}

.zhm--main-page-body {
    width: 100%;
    height: 100vh;
    overflow: auto;
    &.with-subcontentnav {
        height: calc(100vh - 4.8rem);
        padding-top: 0;
        overflow-x: hidden;
    }
}

.zhm--page-body {
    &.page-body-container {
        max-width: 63rem;
        padding: 1.3rem 1.3rem $topbar-desktop-height;
        background: $white;
        border: 1px solid $grey-300;
        margin-top: -2px;
        height: 100%;
        overflow: auto;
    }
}

@include media-breakpoint-down(md) {
    .zhm--page {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }
    .zhm--main-page-body {
        height: auto;
        padding: 0;
        &.with-subcontentnav {
            height: auto;
        }
    }

    .zhm--page-body {
        padding: 0 1rem;
        padding-bottom: 4rem;
        &.page-body-container {
            min-height: 100%;
            height: auto;
        }
    }
}

@media (max-width: 1430px) {
    .zhm--main-page-body {
        max-width: none;
    }
}
