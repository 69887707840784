.icon-file-pdf-new:before {
    content: url('/img/documents/pdf.svg');
}

.icon-file-word-new:before {
    content: url('/img/documents/word.svg');
}

.icon-file-powerpoint-new:before {
    content: url('/img/documents/powerpoint.svg');
}

.icon-file-excel-new:before {
    content: url('/img/documents/excel.svg');
}

.icon-file-image-new:before {
    content: url('/img/documents/image.svg');
}

.icon-file-new:before {
    content: url('/img/documents/other.svg');
}

.icon-file-blue-pdf:before {
    content: url('/img/documents/blue_pdf.svg');
}

.icon-file-blue-csv:before {
    content: url('/img/documents/blue_csv.svg');
}

i {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'legacy-icons', 'rental-icons', 'files-icons', 'money-icons', 'navigation-icons',
        'operations-icons', 'property-types-icons', 'status-icons', 'time-icons', 'user-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
