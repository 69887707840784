.zhm--toggle {
    $color-background-default: $grey-400;
    $color-background-checked: $green-base-700;

    display: inline-block;

    input {
        position: absolute;
        margin-left: -9999px;
        visibility: hidden;

        & + label {
            display: block;
            position: relative;
            width: 64px;
            height: 32px;
            border-radius: 35px;
            cursor: pointer;
            outline: none;
            user-select: none;
            background-color: $color-background-default;
            transition: background-color 500ms linear;

            &:before,
            &:after {
                display: block;
                position: absolute;
                content: '';
            }

            &:after {
                width: 32px;
                height: 32px;
                background-color: white;
                border-radius: 100%;
                box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
                transition: margin 0.4s;
                left: -1px;
            }
        }

        &:checked + label {
            background-color: $color-background-checked;
            transition: background-color 500ms linear;
        }

        &:checked + label:after {
            margin-left: 33px;
        }
    }

    & + small {
        display: inline-block;
        position: relative;
        top: -1em;
        padding-left: 8px;

        @include media-breakpoint-up(md) {
            padding: 0 1em;
        }
    }
}
